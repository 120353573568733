import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { integrationKeys } from "src/data/integrations/queries/integrationQueryCache"
import {
  OwnerType,
  TFetchIntegrationEntityError400,
  TIntegrationEntities,
  TIntegrationEntityPaging,
  TIntegrationId,
} from "src/data/integrations/types/integrationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

type TFetchIntegrationEntities = {
  entities: TIntegrationEntities
  paging: TIntegrationEntityPaging
}

/** Fetch all entities for the integration. */
export function useFetchIntegrationEntities({
  integration,
  orgId,
  ownerType = OwnerType.ORGANIZATION,
  options,
}: {
  integration: TIntegrationId
  orgId: string
  ownerType: OwnerType
  options?: UseQueryOptions<
    TFetchIntegrationEntities,
    AxiosError<TFetchIntegrationEntityError400>,
    TFetchIntegrationEntities,
    ReturnType<typeof integrationKeys.entities>
  >
}) {
  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}~1entities/get */
  async function fetchIntegrationEntities() {
    const response = await minutApiHttpClient.get<TFetchIntegrationEntities>(
      `${API_DEFAULT}/integrations/${integration}/entities`,
      {
        params: {
          owner_id: orgId,
          owner_type: ownerType,
        },
      }
    )
    return response.data
  }

  return useQuery({
    queryKey: integrationKeys.entities({ integration, orgId }),
    queryFn: fetchIntegrationEntities,
    ...options,
  })
}

/** Infinite queries to fetch entities for the integration using cursors */
export function useFetchInfiniteIntegrationEntities({
  integration,
  orgId,
  ownerType = OwnerType.ORGANIZATION,
  filters,
  options,
}: {
  integration: TIntegrationId
  orgId: string
  ownerType: OwnerType
  filters: { limit: number; name?: string }
  options?: UseInfiniteQueryOptions<
    TFetchIntegrationEntities,
    AxiosError,
    TFetchIntegrationEntities,
    TFetchIntegrationEntities,
    ReturnType<typeof integrationKeys.infiniteEntities>
  >
}) {
  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}~1entities/get */
  async function fetchInfiniteIntegrationEntities({ pageParam = undefined }) {
    const response = await minutApiHttpClient.get<TFetchIntegrationEntities>(
      `${API_DEFAULT}/integrations/${integration}/entities`,
      {
        params: {
          owner_id: orgId,
          owner_type: ownerType,
          cursor: pageParam,
          ...filters,
        },
      }
    )

    return response.data
  }

  return useInfiniteQuery({
    queryKey: integrationKeys.infiniteEntities({ integration, orgId }, filters),
    queryFn: fetchInfiniteIntegrationEntities,
    getNextPageParam: (lastPage) => {
      return lastPage.paging.next_cursor
    },
    ...options,
  })
}
