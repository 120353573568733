import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { integrationKeys } from "src/data/integrations/queries/integrationQueryCache"
import {
  OwnerType,
  TGetLinksParams,
  TIntegrationHomeLink,
  TIntegrationHomeLinksFilter,
  TIntegrationId,
  TIntegrationLink,
  TIntegrationLinks,
} from "src/data/integrations/types/integrationTypes"
import { IPaging } from "src/data/pagination/types/paginationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

interface IIntegrationLinkData {
  orgId: string
  integration: TIntegrationId
  linkId?: string
}

/** https://api.staging.minut.com/latest/docs/internal#get-/integrations/-integration_identifier-/links */
async function fetchIntegrationLinks({
  integration,
  owner_id,
  owner_type,
  account_id,
}: TGetLinksParams) {
  const response = await minutApiHttpClient.get<{
    links: TIntegrationLinks
  }>(`${API_DEFAULT}/integrations/${integration}/links`, {
    params: {
      owner_id,
      owner_type,
      account_id,
    },
  })
  return response.data
}

/** Fetch all available integration links for a given integration */
export function useFetchIntegrationLinks(
  {
    integration,
    owner_id,
    owner_type = OwnerType.ORGANIZATION,
    account_id,
  }: TGetLinksParams,
  options?: UseQueryOptions<
    { links: TIntegrationLinks },
    AxiosError,
    { links: TIntegrationLinks },
    ReturnType<typeof integrationKeys.links>
  >
) {
  return useQuery({
    queryKey: integrationKeys.links(
      {
        orgId: owner_id,
        integration,
        ownerType: owner_type,
      },
      account_id
    ),
    queryFn: () =>
      fetchIntegrationLinks({
        integration,
        owner_id,
        owner_type,
        account_id,
      }),
    ...options,
  })
}

interface TMultipleIntegrationLinkResponse {
  id: string
  links: TIntegrationLinks
}

/** Fetch links for multiple integrations in one query */
export function useFetchMultipleIntegrationLinks<
  Select = TMultipleIntegrationLinkResponse[],
>(
  {
    integrationIds,
    orgId,
    ownerType = OwnerType.ORGANIZATION,
  }: {
    integrationIds: TIntegrationId[]
    orgId: string
    ownerType?: OwnerType
  },
  options?: UseQueryOptions<
    TMultipleIntegrationLinkResponse[],
    AxiosError,
    Select,
    ReturnType<typeof integrationKeys.multipleIntegrationLinks>
  >
) {
  function fetchMultipleIntegrationLinks(integrationIds: TIntegrationId[]) {
    return integrationIds.map(async (id) => {
      const integrationLinks = await fetchIntegrationLinks({
        integration: id,
        owner_id: orgId,
        owner_type: ownerType,
      })

      return {
        id: id,
        ...integrationLinks,
      }
    })
  }

  return useQuery({
    queryKey: integrationKeys.multipleIntegrationLinks({
      orgId,
      integrationIds,
    }),
    queryFn: () => Promise.all(fetchMultipleIntegrationLinks(integrationIds)),
    ...options,
  })
}

interface TIntegrationHomeLinksResponse {
  paging: IPaging
  homes: TIntegrationHomeLink[]
}

/** Fetch a paginated list of all the organizations home and their link status */
export function useFetchIntegrationHomeLinks(
  {
    integrationId,
    ownerId,
    ownerType = OwnerType.ORGANIZATION,
    filters,
  }: {
    integrationId: TIntegrationId
    ownerId: string
    ownerType?: OwnerType
    filters?: TIntegrationHomeLinksFilter
  },
  options?: UseQueryOptions<
    TIntegrationHomeLinksResponse,
    AxiosError,
    TIntegrationHomeLinksResponse,
    ReturnType<typeof integrationKeys.integrationHomeLinks>
  >
) {
  async function fetchIntegrationHomeLinks() {
    const response =
      await minutApiHttpClient.get<TIntegrationHomeLinksResponse>(
        `${API_DEFAULT}/integrations/${integrationId}/homes`,
        { params: { owner_id: ownerId, owner_type: ownerType, ...filters } }
      )

    return response.data
  }

  return useQuery({
    queryKey: integrationKeys.integrationHomeLinks(
      {
        integration: integrationId,
        orgId: ownerId,
        ownerType,
      },
      filters
    ),
    queryFn: fetchIntegrationHomeLinks,
    ...options,
  })
}

/** Create a link between a Minut home and an integration entity. */
export function usePostIntegrationLink() {
  interface IMutationParams extends IIntegrationLinkData {
    ownerType: OwnerType
    homeId: string
    entityId: string
    accountId: string
  }
  const queryClient = useQueryClient()
  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}~1links/post */
  async function postLink({
    integration,
    orgId,
    ownerType,
    homeId,
    entityId,
    accountId,
  }: IMutationParams) {
    const params = {
      owner_id: orgId,
      owner_type: ownerType,
    }
    const data = {
      link: { home_id: homeId, entity_id: entityId, account_id: accountId },
    }
    const response = await minutApiHttpClient.post<TIntegrationLink>(
      `${API_DEFAULT}/integrations/${integration}/links`,
      data,
      {
        params,
      }
    )
    return response.data
  }

  return useMutation<
    TIntegrationLink,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    AxiosError<any>,
    IMutationParams
  >({
    mutationFn: postLink,
    onSuccess: (_, params) => {
      const { orgId, integration, ownerType } = params
      // TODO WEB-xxx: The BE docs says a list of links should be returned, but only the
      // posted link is returned. Until we know which, we can't refresh the
      // cache properly, hence we're doing a refetch for now.
      queryClient.invalidateQueries(
        integrationKeys.integrationHomeLinks({ orgId, integration, ownerType })
      )
      queryClient.invalidateQueries(
        integrationKeys.links({ orgId, integration, ownerType })
      )
    },
  })
}

/** Update a specific link. */
// ts-prune-ignore-next
export function usePutIntegrationLink() {
  const queryClient = useQueryClient()

  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}~1links~1{link_id}/put */
  async function putLink({
    integration,
    linkId,
    orgId,
    payload,
  }: IIntegrationLinkData & {
    payload: { home_id: string; entityId: string }
  }) {
    const response = await minutApiHttpClient.put<{
      links: TIntegrationLinks
    }>(`${API_DEFAULT}/integrations/${integration}/links/${linkId}`, payload, {
      params: {
        owner_id: orgId,
        owner_type: OwnerType.ORGANIZATION,
      },
    })
    return response.data
  }

  return useMutation(putLink, {
    onSuccess: (resp, data) => {
      const { orgId, integration } = data
      queryClient.setQueryData<{ links: TIntegrationLinks }>(
        integrationKeys.links({ orgId, integration }),
        resp
      )
    },
    // Defining an error here so that the generic Error type can be inferred:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    onError: (err: AxiosError<any>) => {
      throw err
    },
  })
}

/** Remove the specified link. */
export function useDeleteIntegrationLink() {
  const queryClient = useQueryClient()

  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}~1links~1{link_id}/delete */
  async function deleteLink({
    integration,
    linkId,
    orgId,
    ownerType = OwnerType.ORGANIZATION,
  }: IIntegrationLinkData & { linkId: string; ownerType: OwnerType }) {
    const response = await minutApiHttpClient.delete<AxiosResponse>(
      `${API_DEFAULT}/integrations/${integration}/links/${linkId}`,
      {
        params: {
          owner_id: orgId,
          owner_type: ownerType,
        },
      }
    )
    return response.data
  }

  return useMutation({
    mutationFn: deleteLink,
    onSuccess: async (_, vars) => {
      const { orgId, integration, ownerType } = vars

      return Promise.all([
        queryClient.invalidateQueries(
          integrationKeys.integrationHomeLinks({
            integration,
            orgId,
            ownerType,
          })
        ),
        queryClient.invalidateQueries(
          integrationKeys.links({ orgId, integration })
        ),
      ])
    },
    // Defining an error here so that the generic Error type can be inferred:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    onError: (err: AxiosError<any>) => {
      throw err
    },
  })
}
