import { usePostTrackEventWithParams } from "src/data/analytics/queries/analyticsQueries"
import { EventContext } from "src/data/integrations/types/integrationTypes"

type TIntegrationEvents =
  | "Integrations List Viewed"
  | "Integration Searched"
  | "Integration Filters Viewed"
  | "Integration Filter Applied"
  | "Integration Viewed"
  | "Integration Connect Initiated"
  | "Integration Consent Confirmed"
  | "Integration Link Home Skipped"
  | "Integration Link Home Confirmed"
  | "Integration Unlink Home Initiated"
  | "Integration Link Home Initiated"
  | "Integration Home Searched"
  | "Integration Listing Searched"
  | "Thermostat Settings Edit Intiated"
  | "Thermostat Settings Edited"

function usePostTrackIntegrationsEvents<TEventProperties>(
  event: TIntegrationEvents
) {
  return usePostTrackEventWithParams<TIntegrationEvents, TEventProperties>({
    event,
  })
}

export function usePostIntegrationListViewEvent() {
  return usePostTrackIntegrationsEvents("Integrations List Viewed")
}

export function usePostIntegrationSearchedEvent() {
  return usePostTrackIntegrationsEvents("Integration Searched")
}

export function usePostIntegrationFiltersViewedEvent() {
  return usePostTrackIntegrationsEvents("Integration Filters Viewed")
}

export function usePostIntegrationFilterAppliedEvent() {
  return usePostTrackIntegrationsEvents("Integration Filter Applied")
}

export function usePostIntegrationViewedEvent() {
  return usePostTrackIntegrationsEvents("Integration Viewed")
}

export function usePostIntegrationConnectInitiated() {
  return usePostTrackIntegrationsEvents("Integration Connect Initiated")
}

export function usePostIntegrationConsentConfirmedEvent() {
  return usePostTrackIntegrationsEvents("Integration Consent Confirmed")
}

export function usePostIntegrationLinkHomeSkippedEvent() {
  return usePostTrackIntegrationsEvents<{ integration_name: string }>(
    "Integration Link Home Skipped"
  )
}

export function usePostIntegrationLinkHomeConfirmedEvent() {
  return usePostTrackIntegrationsEvents<{
    integration_name: string
    context: EventContext
  }>("Integration Link Home Confirmed")
}

export function usePostIntegrationUnlinkInit() {
  return usePostTrackIntegrationsEvents<{ integration_name: string }>(
    "Integration Unlink Home Initiated"
  )
}

export function usePostIntegrationListingSearchedEvent() {
  return usePostTrackIntegrationsEvents<{
    integration_name: string
    context: EventContext
  }>("Integration Listing Searched")
}

export function usePostIntegrationHomeSearchedEvent() {
  return usePostTrackIntegrationsEvents<{
    integration_name: string
  }>("Integration Home Searched")
}

export function usePostIntegrationLinkHomeInitiatedEvent() {
  return usePostTrackIntegrationsEvents<{
    integration_name: string
    context: EventContext
  }>("Integration Link Home Initiated")
}

export function usePostThermostatSettingsEditIntiated() {
  return usePostTrackIntegrationsEvents("Thermostat Settings Edit Intiated")
}

export function usePostThermostatSettingsEdited() {
  return usePostTrackIntegrationsEvents("Thermostat Settings Edited")
}
